<template>
  <p>404</p>
</template>

<script>
export default {
  name: '404',
};
</script>

<style scoped lang="scss"></style>
